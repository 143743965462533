import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import useWindowSize from 'hooks/useWindowSize';
import removeBr from 'helpers/removeBr';
import slugify from 'helpers/slugify';
import Metas from 'components/metas';
import SmartLink from 'components/smartLink';
import TextWithBr from 'components/textWithBr';
import ExampleContentCard from 'components/exampleContentCard';
import Billboard from 'components/billboard';
import { Choosy, OptionGroup, Option } from 'components/choosy';
import styles from './browse-content.module.scss';

const isClient = typeof window !== 'undefined';

export default function BrowseContentPage({ location }) {
  /*
   *   Query blog posts
   */
  const exampleContentData = useStaticQuery(graphql`
    query ExampleContentGroupsQuery {
      allContentfulExampleContentGroup {
        nodes {
          ... on Node {
            ... on ContentfulExampleContentGroup {
              id
              subcategory
              title
              hideTitle
              link
              category {
                title
                id
                categoryGroup
                description {
                  description
                }
                colorTheme
              }
              exampleContentCards {
                ... on Node {
                  ... on ContentfulExampleContentCard {
                    id
                    link
                    image {
                      title
                      fluid(maxWidth: 700) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                      description
                    }
                    icons {
                      id
                      title
                      file {
                        url
                      }
                    }
                    label
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [containerRef, inView] = useInView({ triggerOnce: true });
  const windowSize = useWindowSize(500);
  const isMobile = windowSize.width < 1081;
  const groups = exampleContentData.allContentfulExampleContentGroup.nodes;
  const [currentCategory, setCurrentCategory] = useState(groups[0].category);
  const [extraIsOpen, setExtraIsOpen] = useState(false);

  const subcategories = new Set(
    groups
      .filter((g) => g.category.id === currentCategory.id)
      .map((g) => g.subcategory)
      .sort()
      .sort((a, b) => {
        if (a === 'Collections') {
          return 1;
        } else if (b === 'Collections') {
          return -1;
        } else {
          return 0;
        }
      })
  );
  const [currentSubCategory, setCurrentSubCategory] = useState(
    Array.from(subcategories).sort()[0]
  );
  const tabGroups = groups.reduce((acc, curr) => {
    const catGroup = groups
      .filter((g) => g.category.categoryGroup === curr.category.categoryGroup)
      .reduce((acc2) => ({ ...acc2, [curr.category.id]: curr.category }), {});

    return {
      ...acc,
      [curr.category.categoryGroup]: {
        ...acc[curr.category.categoryGroup],
        ...catGroup,
      },
    };
  }, {});

  const catIndex = Object.keys(tabGroups).reduce((acc, curr) => {
    const tabGroup = tabGroups[curr];
    const tabs = Object.keys(tabGroup).map((key) => tabGroup[key]);
    const catObj = tabs.reduce((acc, curr) => {
      return { [slugify(curr.title).toLowerCase()]: curr, ...acc };
    }, {});
    return { ...catObj, ...acc };
  }, {});

  const currentCategoryGroups = groups
    .filter(
      (g) =>
        g.category.id === currentCategory.id &&
        g.subcategory === currentSubCategory
    )
    .sort((a, b) => b.title.localeCompare(b.title));

  /*
   *   Set current category
   *   Set subcategory to first available
   */
  function updateCategory(cat) {
    const subcats = new Set(
      groups
        .filter((g) => g.category.id === cat.id)
        .map((g) => g.subcategory)
        .sort()
        .sort((a, b) => {
          if (a === 'Collections') {
            return 1;
          } else if (b === 'Collections') {
            return -1;
          } else {
            return 0;
          }
        })
    );
    setCurrentCategory(cat);
    setCurrentSubCategory(Array.from(subcats)[0]);
  }

  /*
   *   hash-based tab nav
   */
  useEffect(() => {
    if (
      isClient &&
      location.hash &&
      slugify(currentCategory.title).toLowerCase() !== slugify(location.hash)
    ) {
      const category = catIndex[slugify(location.hash)];
      if (category) updateCategory(category);
    }
  }, []); //eslint-disable-line

  /*
   *   Tabs
   */

  const choosyOptionGroups = Object.keys(tabGroups).map((key) => {
    const tabGroup = tabGroups[key];
    const tabs = Object.keys(tabGroup).map((key) => tabGroup[key]);

    const tabEls = tabs.map((category) => (
      <Option
        key={category.id}
        onClick={() => updateCategory(category)}
        className={classnames(styles.dropdown_option, {
          [styles.current]: currentCategory.id === category.id,
        })}
      >
        {removeBr(category.title)}
      </Option>
    ));

    return (
      <OptionGroup key={key} title={key}>
        {tabEls}
      </OptionGroup>
    );
  });

  const tabGroupEls = isMobile ? (
    <Choosy label={removeBr(currentCategory.title)} className={styles.choosy}>
      {choosyOptionGroups}
    </Choosy>
  ) : (
    Object.keys(tabGroups).map((key) => {
      const tabGroup = tabGroups[key];
      const tabs = Object.keys(tabGroup).map((key) => tabGroup[key]);

      const tabEls = tabs.map((category) => (
        <button
          key={category.id}
          onClick={() => updateCategory(category)}
          className={classnames(styles.tab, styles[category.colorTheme], {
            [styles.current]: currentCategory.id === category.id,
          })}
        >
          <TextWithBr content={category.title} />
        </button>
      ));

      return (
        <div key={key} className={styles.tab_group}>
          <h4 className={styles.tab_group__title}>{key}</h4>
          <div className={styles.tab_group__tabs}>{tabEls}</div>
        </div>
      );
    })
  );

  /*
   *   Subcategories
   */

  const subcategoryEls = Array.from(subcategories).map((sc) => (
    <button
      key={sc}
      className={classnames(styles.subcategory, {
        [styles.current]: sc === currentSubCategory,
      })}
      onClick={() => setCurrentSubCategory(sc)}
      style={{ maxWidth: `${100 / Array.from(subcategories).length}%` }}
    >
      {sc}
    </button>
  ));

  /*
   *   Card groups
   */

  const currentCategoryGroupEls = currentCategoryGroups.map((cg) => {
    const cardEls = cg.exampleContentCards.map((c) => (
      <ExampleContentCard
        className={styles.card}
        key={c.id}
        title={c.title}
        label={c.label}
        image={c.image}
        icons={c.icons}
        url={c.link}
      />
    ));

    return (
      <section key={cg.id} className={styles.card_group}>
        {!cg.hideTitle && (
          <header className={styles.card_group__header}>
            <h1 className={styles.card_group__title}>{cg.title}</h1>
            {!isMobile && cg.link && (
              <SmartLink to={cg.link} className={styles.card_group__button}>
                See all
              </SmartLink>
            )}
          </header>
        )}
        <div className={styles.cards}>{cardEls}</div>
        {isMobile && !cg.hideTitle && cg.link && (
          <footer className={styles.card_group__footer}>
            <SmartLink to={cg.link} className={styles.card_group__button}>
              See all
            </SmartLink>
          </footer>
        )}
      </section>
    );
  });

  const footerText = {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Ready to engage, support, and grow every learner?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  };

  /*
   *   @todo make this CMS configurable
   */
  const extraCategories = {
    'Newsela ELA': [
      'Art & Culture',
      'Articles by Subject',
      'Biographies',
      'Careers & Education',
      'Classics',
      'Essays',
      'Ew, Yuck!',
      'Fables, Fairy Tales & Folktales',
      'Fiction',
      'Government & Economics',
      'Happy Holidays',
      'Historical Fiction',
      'Homor: Have a Laugh',
      'Horror',
      'How do I...?',
      'Interesting Animals',
      'Interviews',
      'Kids Who Inspire',
      'Making Your Point',
      'Myths & Legends',
      'Op-Eds',
      'Overviews',
      'Poetry',
      'Primary Sources',
      'Pro/Cons',
      'Procedural',
      'Reader Favorites',
      'Religion & Philosophy',
      'Reviews',
      'Sci-Fi',
      'Science & Math',
      'Short Stories',
      'Speeches',
      'Student Opinions',
      'The Sports Section',
      'Themes',
    ],
    'Newsela Social Studies': [
      'Africa',
      'Age of Enlightenment and Revolutions',
      'Age of Exploration and First Global Age',
      'American Revolution',
      'Ancient Civilizations',
      'Art',
      'Artifacts',
      'Buddhism',
      'Byzantine and Russian Empires',
      'Cartoons',
      'Central and South America',
      'Central Asia',
      'Checks & Balances',
      'Christianity',
      'Citizenship',
      'Civics',
      'Civil Rights Movement',
      'Civil War & Reconstruction',
      'Civilizations in the Americas',
      'Cold War',
      'Contemporary Presidents',
      'Decades of the 20th Century',
      'Domestic Policy',
      'Early Americas',
      'Early Human History',
      'East and Southeast Asia',
      'Economics',
      'Egypt and Nubia',
      'Entrepreneurship and Careers',
      'Environment and Society',
      'Europe',
      'European Renaissance and Reformation',
      'Executive Branch',
      'Foreign Policy',
      'Foundations of Government',
      'Fundamentals of Economics',
      'Geography',
      'Gilded Age',
      'Global Trade',
      'Graphics',
      'Great Depression and New Deal',
      'Greece',
      'Hinduism',
      'Historic News',
      'Imperialism',
      'Industrial Revolution',
      'Islam',
      'Japan and East Asia',
      'Judaism',
      'Judicial Branch',
      'Landmark Documents',
      'Latin American Independence',
      'Legislative Branch',
      'Letters / Personal Accounts',
      'Local & State Government',
      'Map Skills and Geographic Tools',
      'Maps',
      'Medieval China',
      'Medieval West Africa',
      'Medieval Western Europe',
      'Modern Globalization',
      'Modern Latin Ameria',
      'Modern Middle East',
      'Multimedia',
      'Myths, Legends, Folktales',
      'Native American History',
      'New Nation to Civil War',
      'North Africa and Middle East',
      'North America',
      'Oceania',
      'Personal Finance',
      'Photos',
      'Primary Sources',
      'Progressive Era',
      'Regions of the United States',
      'River Valley Civilizations',
      'Rome',
      'Silk Road and Eurasian Trade',
      'South Asia',
      'Speeches',
      'Spread of Islam and Islamic Empires',
      'Supreme Court Cases',
      'Taxes',
      'Thirteen Colonies',
      'U.S. Constitution',
      'U.S. Founding Documents',
      'U.S. History',
      'U.S. Imperialism',
      'Voting and Elections',
      'Westward Expansion',
      'World History',
      'World War I',
      'World War II',
    ],
    'Newsela Science': [
      'Applied Math',
      'Arithmetic: Numbers, Fractions, and Ratios',
      'Astronomy',
      'Atoms & The Periodic Table',
      'Biodiversity',
      'Biological Processes',
      'Biology',
      'Biomes and Habitats',
      'Chemistry',
      'Classification',
      'Diagrams',
      'Earth Science',
      "Earth's Systems",
      'Ecosystems',
      'Electricity & Magnetism',
      'Energy',
      'Evolution',
      'Experiments',
      'Experiments',
      'Forces & Motion',
      'Genetics',
      'Geology',
      'Geometry',
      'Human & Earth Interactions',
      'Interactions of Matter',
      'Learning Math: Strategies and Experiences',
      'Math Connections',
      'Mathematicians & Math History',
      'Matter',
      'Nuclear Chemistry',
      'Oceans & Marine Life',
      'Organic Chemistry',
      'Physics',
      'Plots & Charts',
      'Science & Engineering Skills',
      'Science Discussions',
      'Science News',
      'Scientists & STEM Discoveries',
      'Solar System',
      'Solutions & Mixtures',
      'Stars',
      'Statistics',
      'Structure & Function of Living Things',
      'Sun',
      'Sun-Earth-Moon Interactions',
      'Units of Measurement and Conversions',
      'Universe',
      'Waves',
      'Weather & Climate',
    ],
  };

  const extraCatEls = extraCategories[currentCategory.title]
    ? extraCategories[currentCategory.title].map((item) => (
        <li key={item}>{item}</li>
      ))
    : null;
  /*
   *   Return
   */
  return (
    <>
      <Metas
        title="Content Examples & Text Sets"
        description="Browse different examples of Newsela content custom made for specific subjects and reading levels. Find content for social studies, science, ELA, SEL and more."
      />
      <section
        className={classnames(
          styles.container,
          styles[currentCategory.colorTheme],
          {
            [styles.revealed]: inView,
          }
        )}
      >
        <header className={styles.header} ref={containerRef}>
          <h1 className={styles.title}>Check out examples of our content</h1>
          <div className={styles.tabs}>{tabGroupEls}</div>
          {currentCategoryGroups[0] &&
            currentCategoryGroups[0].category.description && (
              <div className={styles.description}>
                {currentCategoryGroups[0].category.description.description}
              </div>
            )}
          {subcategories.size > 1 && (
            <div className={styles.subcategories}>{subcategoryEls}</div>
          )}
        </header>
        <div className={styles.groups}>{currentCategoryGroupEls}</div>
        <footer className={styles.footer}>
          {subcategories.size > 1 && (
            <div className={styles.subcategories}>{subcategoryEls}</div>
          )}

          {/* @todo make this CMS configurable */}
          {extraCatEls && extraIsOpen && (
            <div className={styles.extra}>
              <h3 className={styles.extra__title}>
                Example of available categories:
              </h3>
              <ul className={styles.extra__list}>{extraCatEls}</ul>
            </div>
          )}

          {extraCatEls && (
            <button
              className={styles.footer__button}
              onClick={() => setExtraIsOpen(!extraIsOpen)}
            >
              {extraIsOpen ? 'Show less' : 'Show more'}
            </button>
          )}
        </footer>
      </section>
      <Billboard
        title="Bring the meaning behind every lesson forward."
        subtitle={footerText}
        buttonText="Get in touch with our team"
        buttonUrl="https://go.newsela.com/contact"
        backgroundColor="Blue"
      />
    </>
  );
}
